import { inject } from "@angular/core";
import { CanActivateFn, UrlTree, Router, ActivatedRouteSnapshot } from "@angular/router";

import { Observable } from "rxjs";

import { AuthenticationService, FeatureFlagService } from "../services";

export const FeatureFlagGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authenticationService = inject(AuthenticationService);
  const featureFlagService = inject(FeatureFlagService);
  const defaultRoute = authenticationService.getDefaultRoute();

  return (
    featureFlagService.isEnabled(route.data["featureFlag"]) ||
    inject(Router).createUrlTree([`/${defaultRoute}`])
  );
};
