import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthenticationService } from "../services";

@Injectable()
export class NotLoggedInGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  async canActivate(): Promise<boolean> {
    if (this.authenticationService.isLocked()) {
      return true;
    } else {
      const defaultRoute = this.authenticationService.getDefaultRoute();
      const returnUrl = this.route.snapshot.queryParams["returnUrl"] || `/${defaultRoute}`;

      this.router.navigate([returnUrl]);

      return false;
    }
  }
}
