import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService } from "../services";

@Injectable()
export class DefaultRouteGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    const defaultRoute = this.authenticationService.getDefaultRoute();

    this.router.navigate([`/${defaultRoute}`]);

    return false;
  }
}
