import { inject } from "@angular/core";
import { CanActivateFn, UrlTree, Router } from "@angular/router";

import { Observable } from "rxjs";

import { AuthenticationService } from "../services";

export const EntitiesWriteAccessGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authenticationService = inject(AuthenticationService);
  const defaultRoute = authenticationService.getDefaultRoute();

  return (
    authenticationService.canAddModifyEntities() ||
    inject(Router).createUrlTree([`/${defaultRoute}`])
  );
};
